<template>
  <div class="article-list">
    <div class="begin bg-white p-4 rounded-lg">
      <div
        class="image rounded-lg w-full h-48"
        v-bind:style="{ 'background-image': 'url(' + file + ')' }"
      ></div><br>
      <div class="title font-medium text-black leading-relaxed">
        {{ articleList.title }}
      </div>
      <div class="descrioption mt-2 text-sm leading-relaxed">
        {{ truncateString(articleList.description, 50) }}
      </div><br>
      <div class="footer flex items-center justify-between">
        <div class="">
          <span v-if="articleList.type === 'ARTICLE'">Article</span>
          <span v-if="articleList.type === 'ASTUCE'">Astuce</span>
        </div>
        <div class="flex items-center justify-end">
          <div class="number-view flex items-center mr-4">
            <div class="flex items-center">
              <svg-icon
                name="ic_eye-open"
                original
                class="w-4 h-4 mr-2"
              />
              <div class="hour text-sm">{{ articleList.view }}</div>
            </div>
          </div>
          <div class="date flex items-center">
            <div class="flex items-center">
              <svg-icon
                name="ic_carbon_time"
                original
                class="w-4 h-4 mr-2"
              />
              <div class="hour text-sm">{{ new Intl.DateTimeFormat('fr-FR').format(new Date(articleList.createdAt)) }}</div>
            </div>
          </div>
          <div class="flex items-center ml-4">
            <div class="update mr-4" @click.stop="$emit('openUpdateArticle', articleList)">
              <svg-icon
                name="ic_update"
                original
                class="w-4 h-4"
              />
            </div>
            <div class="delete" @click.stop="$emit('openConfirmation', articleList.id)">
              <svg-icon
                name="ic_delete-red"
                original
                class="w-4 h-4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '../../../plugins/http'
import apiRoute from '../../../router/api-routes'
export default {
  name: 'article-list',
  props: {
    articleList: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      file: ''
    }
  },
  created () {
    this.getArticleFile()
    // console.log(this.articleList)
  },
  methods: {
    getArticleFile () {
      http.get(apiRoute.baseURL + apiRoute.getFileById + this.articleList.pictureId, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      })
        .then(response => {
          // console.log(response)
          this.file = response.file
        }).catch(error => {
          console.log(error)
        })
    },
    truncateString (str, num) {
      if (str.length <= num) {
        return str
      }
      return str.slice(0, num) + '...'
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/style/sass/variables";
  .image {
    background-image: url("../../../assets/images/img_medoc.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
</style>
