<template>
  <div class="add-article">
    <div class="begin pb-20">
      <div class="back cursor-pointer">
        <svg-icon
          name="ic_go-back"
          original
          class="w-8"
          @click="goBack"
        />
      </div><br>
      <div class="form bg-white rounded-lg p-8 ml-20">
        <div id="circuit" class="titre flex items-center justify-between">
          <div class="title font-medium text-black text-2xl w-3/4">
            {{ $t('newArticle') }}
          </div><br>
          <div class="publication-status italic w-1/4">
            <div
              v-if="!publish"
              class="current-p w-3/4"
            >
              {{ $t('currentPublication') }}
            </div>
            <div
              v-if="publish"
              class="publish flex items-center"
            >
              <div class="pub mr-4">{{ $t('publishedArticle') }}</div>
              <svg-icon
                name="ic_feather_check-circle"
                original
                class="w-4 h-4"
              />
            </div>
          </div>
        </div><br>
        <div class="form-content flex items-center">
          <div class="part-1 w-3/4 pr-8">
            <div class="login-form">
              <div class="label mb-3">{{ $t('articleTitle') }}*</div>
              <div class="input">
                <label>
                  <textarea
                    v-model="title"
                    class="ipt border-b w-full p-4 font-semibold text-lg resize-none"
                  ></textarea>
                </label>
              </div>
            </div><br>
            <div class="login-form">
              <div class="label mb-3">{{ $t('articleDescription') }}*</div>
              <div class="input">
                <label>
                  <textarea
                    v-model="description"
                    maxlength="255"
                    class="ipt border-b w-full p-4 font-semibold text-lg resize-none"
                  ></textarea>
                  <div class="text-red-500 text-right text-sm">
                    {{ description.length + "/255" }}
                  </div>
                </label>
              </div>
            </div><br>
            <div class="login-form">
              <div class="input">
                <Vueditor ref="editor"></Vueditor>
              </div>
            </div><br>
            <div class="login-button mt-4">
              <button-base
                :label="$t('publicationButton')"
                :is-loading="loader"
                @click.native="publishArticle"
              />
            </div>
          </div>
          <div class="part-2 w-1/4">
            <div class="login-form">
              <div class="label mb-2">{{ $t('type') }}*</div>
              <div class="input relative">
                <label>
                  <select
                    v-model="type"
                    class="ipt rounded-lg w-full py-4 pl-4 pr-12 border appearance-none bg-white"
                  >
                    <option value="ARTICLE">Article</option>
                    <option value="ASTUCE">Astuce</option>
                  </select>
                </label>
                <div class="absolute top-0 right-0 p-4">
                  <svg-icon
                    name="ic_down"
                    original
                    class="w-4 h-4"
                  />
                </div>
              </div>
            </div><br>
            <div class="login-form relative">
              <div class="label mb-3">{{ $t('articlePicture') }}* (Moins de 1Mo)</div>

              <image-uploader
                :preview="true"
                :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                :quality="0.9"
                :scaleRatio="1"
                capture="environment"
                accept="image/*"
                :debug="1"
                doNotResize="gif"
                :autoRotate="true"
                outputFormat="verbose"
                @input="setImage"
              >
                <label for="fileInput" slot="upload-label">
                  <div v-if="!hasImage" class="input">
                    <div class="ipt py-8 w-full border rounded-lg cursor-pointer">
                      <div class="icones mb-3">
                        <svg-icon
                          name="ic_add-image"
                          original
                          class="w-8 h-8 m-auto"
                        />
                      </div>
                      <div class="add text-center">{{ $t('selectionImage') }}</div>
                    </div>
                  </div>
                  <div v-if="hasImage" class="close-pic absolute cursor-pointer text-red-400 py-1 px-2 rounded-full bg-white w-fit shadow">+</div>
                </label>
              </image-uploader>
            </div><br>
            <div
              v-if="error"
              class="error text-red-500"
            >
              {{ errorAdd }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- LOADER FOR WAITING SAVE -->
    <div class="loading" :class="{ 'not_empty': waitLoader === false }">
      <loading
        :active="waitLoader"
        :can-cancel="true"
        :on-cancel="onCancel"
        :is-full-page="fullPage"
        loader="dots"
        color="#0B5382"
        background-color="transparent"
      />
    </div>

    <success-add v-if="isSuccess" message="Nouvel article ajouté avec succès" button-label="Retour" @closeSuccess="closeSuccess"></success-add>
  </div>
</template>

<script>
import ButtonBase from '../add/button-base'
import http from '../../../plugins/http'
import apiRoute from '../../../router/api-routes'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import SuccessAdd from '@/components/helper/success-add.vue'
export default {
  name: 'add-article',
  components: { SuccessAdd, ButtonBase, Loading },
  props: {
    categoryInformation: Object,
    articleData: Object
  },
  data () {
    return {
      waitLoader: false,
      fullPage: true,
      error: false,
      errorAdd: '',
      loader: false,
      publish: false,
      title: '',
      description: '',
      content: '',
      type: '',
      pictureId: null,
      allCategories: [],
      hasImage: false,
      image: null,
      isSuccess: false
    }
  },
  created () {
    this.getCategories()
  },
  mounted () {
    if (this.articleData) {
      console.log(this.articleData)
      this.title = this.articleData.title
      this.description = this.articleData.description
      this.type = this.articleData.type
      this.pictureId = this.articleData.pictureId
      const editor = this.$refs.editor
      // console.log(editor.setContent)
      editor.setContent(this.articleData.article[0].content)
    }
    const editor = this.$refs.editor
    editor.upload = function (obj, callback) {
      const file = obj.files[0]
      // console.log(file)
      const formData = new FormData()
      formData.append('file', file)
      this.waitLoader = true
      http.post(apiRoute.baseURL2 + apiRoute.uploadFile, formData, {
        headers: {
          'x-api-key': 'a2f2db80-6d5a-4a95-adf3-6b66a778d48b'
        }
      }).then(response => {
        console.log(response.url)
        setTimeout(() => { callback(response.url) }, 3000)
      }).catch(error => {
        console.log(error)
      })
      /* const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        const fileName = reader.result
      } */
    }
    editor.getContent()
    console.log(editor.getContent())
  },
  methods: {
    closeSuccess (value) {
      this.isSuccess = value
      this.$emit('closeSuccess', false)
    },
    setImage: async function (output) { // FUNCTION TO COMPRESS IMAGE
      this.hasImage = true
      this.image = output
      console.log(this.image)
      console.log('Info', output.info)
      console.log('Exif', output.exif)
      // this.createFileId(this.image.dataUrl)
    },
    async publishArticle () { // SAVE NEW ARTICLE
      const editor = this.$refs.editor
      this.content = editor.getContent()
      const elmt = {}
      elmt.title = this.title
      elmt.content = this.content
      const table = []
      table.push(elmt)
      // console.log(table)
      // console.log(this.content)
      if (this.title === '' || this.content === '' || this.type === '' || this.description === '') {
        this.error = true
        this.errorAdd = this.$t('errorAddAdmin')
      } else {
        this.waitLoader = true
        this.error = false
        if (this.articleData) {
          console.log('update')
          const picture = this.image ? await this.createFileId(this.image.dataUrl) : this.pictureId

          await http.patch(apiRoute.baseURL + apiRoute.updateArticle, {
            id: this.articleData.id,
            title: this.title,
            type: this.type,
            description: this.description,
            categorieId: this.categoryInformation.id,
            pictureId: picture,
            article: table
          }, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.loginToken}`
            }
          }).then(response => {
            console.log(response)
            this.publish = true
            const element = document.getElementById('circuit')
            element.scrollIntoView(false)
            this.waitLoader = false
            this.title = ''
            this.content = editor.setContent()
            this.category = ''
            this.fileName = ''
            this.image = null
            this.hasImage = false
            this.description = ''
            this.type = ''
            this.articleData = {}
          }).catch(error => {
            console.log(error)
            this.waitLoader = false
          })
        } else {
          await this.createFileId(this.image.dataUrl)

          await http.post(apiRoute.baseURL + apiRoute.createNewArticle, {
            title: this.title,
            type: this.type,
            description: this.description,
            categorieId: this.categoryInformation.id,
            pictureId: this.pictureId,
            article: table
          }, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.loginToken}`
            }
          }).then(response => {
            console.log(response)
            this.publish = true
            const element = document.getElementById('circuit')
            element.scrollIntoView(false)
            this.waitLoader = false
            this.title = ''
            this.content = editor.setContent()
            this.category = ''
            this.fileName = ''
            this.image = null
            this.hasImage = false
            this.description = ''
            this.type = ''
            this.articleData = {}
          }).catch(error => {
            console.log(error)
            this.waitLoader = false
          })
        }
      }
    },
    getCategories () { // GET BLOG ALL CATEGORIES
      http.post(apiRoute.baseURL + apiRoute.getCategoryByType, {
        type: 'BLOG'
      }, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      }).then(response => {
        console.log(response)
        this.allCategories = response
      }).catch(error => {
        console.log(error)
      })
    },
    createFileId (filename) { // SEND IMAGE TO GET ID
      return new Promise((resolve, reject) => {
        http.post(apiRoute.baseURL + apiRoute.createFile, {
          file: filename
        }, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.loginToken}`
          }
        }).then(response => {
          console.log(response)
          this.pictureId = response.id
          resolve(this.pictureId)
        }).catch(error => {
          console.log(error)
        })
      })
    },
    goBack () { // GO BACK TO ARTICLE LIST
      this.$emit('closeAddArticle', false)
    },
    onCancel () {
      console.log('User cancelled the loader.')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/style/sass/variables";
  .svg-fill {
    fill: transparent;
  }
  .ipt {
    background-color: $light_gray3;
    color: black;
  }
  .close-pic {
    width: fit-content;
    top: 15px;
    right: -10px;
  }
  .login-form::v-deep {
    #fileInput {
      display: none !important;
    }
    img {
      border: 1px solid #E2E8F0;
      border-radius: 0.5rem;
      height: 8rem;
    }
  }
  .vueditor::v-deep {
    // background: $light_gray3;
    border: 0;
    .ve-toolbar {
      div {
        // padding: 10px 10px;
        .ve-divider {
          padding: 0;
        }
      }
      background: $light_gray3;
      border: 1px solid #DDDDDD;
      border-radius: .5rem;
      margin-bottom: 1.5rem;
      i {
        opacity: 1;
        width: 14px;
        height: 14px;
      }
      div:hover {
        background: $pass_marron;
        border-radius: .5rem;
      }
      .ve-active {
        background: $pass_marron;
        border-radius: .5rem;
        color: $white;
      }
    }
    .ve-design {
      background: $white;
      border: 1px solid #DDDDDD;
      border-radius: .5rem;
      min-height: 20rem;
      padding: 1rem;
    }
  }
  .quillWrapper ::v-deep {
    .ql-toolbar.ql-snow {
      background: $light_gray3;
      border-radius: .5rem;
      margin-bottom: 1rem;
      border: 0 solid;
      button {
        svg {
          height: 20px;
        }
        &.ql-active {
          background: $pass_marron;
          border-radius: .25rem;
          color: $white;
          svg {
            .ql-stroke {
              stroke: $white;
            }
          }
        }
      }
    }
    .ql-container.ql-snow {
      border-radius: .5rem;
      min-height: 15rem;
    }
  }
  .login-button ::v-deep {
    .button-base {
      text-align: -moz-right;
      text-align: -webkit-right;
      .page-button-real {
        width: 10rem;
      }
    }
  }
  .icones {
    text-align: -moz-center;
    text-align: -webkit-center;
  }
</style>
