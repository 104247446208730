<template>
  <div class="add-category">
    <popup-base>
      <div class="begin bg-white m-auto w-3/5 rounded-lg p-8">
        <div class="back">
          <svg-icon
            name="ic_go-back"
            original
            class="w-8 h-8 cursor-pointer"
            @click="goBack"
          />
        </div><br>
        <div class="title text-black font-medium text-2xl">{{ $t('newCategory') }}</div><br>
        <div class="form mt-4">
          <div class="login-form">
            <div class="label mb-2">{{ $t('categoryLabel') }}*</div>
            <div class="input">
              <label>
                <input
                  v-model="label"
                  type="text"
                  class="ipt p-4 border w-full rounded-lg"
                >
              </label>
            </div>
          </div><br>
          <div class="login-form">
            <div class="label mb-2">{{ $t('description') }}*</div>
            <div class="input">
              <label>
                <textarea
                  v-model="description"
                  rows="4"
                  class="ipt p-4 border w-full resize-none rounded-lg"
                ></textarea>
              </label>
            </div>
          </div><br>
<!--          <div class="login-form">
            <div class="label mb-2">{{ $t('type') }}</div>
            <div class="input relative">
              <label>
                <select
                  v-model="type"
                  class="ipt rounded-lg w-full py-4 pl-4 pr-12 border appearance-none bg-white z-50"
                >
                  <option value="QUIZ">Quiz</option>
                  <option value="SSR">Point SSR</option>
                  <option value="BLOG">Blog</option>
                </select>
              </label>
              <div class="absolute top-0 right-0 p-4 z-10">
                <svg-icon
                  name="ic_down"
                  original
                  class="w-4 h-4"
                />
              </div>
            </div>
          </div><br>-->
          <div class="login-form">
            <div class="label mb-3">{{ $t('categoryIcon') }}* (Moins de 1Mo)</div>
            <div class="input">
              <div
                v-if="!fileName"
                class="ipt py-8 w-full border rounded-lg cursor-pointer"
                @click="choosePicture"
              >
                <div class="icones mb-3">
                  <svg-icon
                    name="ic_add-image"
                    original
                    class="w-8 h-8 m-auto"
                  />
                </div>
                <div class="add text-center">{{ $t('selectionIcon') }}</div>
              </div>
              <div v-if="fileName" class="show border rounded-lg w-full h-32 relative">
                <img :src="fileName" class="rounded-lg h-32 w-full" alt="icône de la catégorie">
                <div class="close-pic absolute cursor-pointer text-red-400 py-1 px-2 rounded-full bg-white w-fit shadow" @click="choosePicture">+</div>
              </div>
            </div>
          </div><br>
          <div
            v-if="error"
            class="error text-red-500 text-center"
          >
            {{ errorAdd }}
          </div><br>
          <div class="login-button">
            <button-base
              :label="$t('addButton')"
              :is-loading="loader"
              @click.native="saveCategory"
            />
          </div>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from '../add/popup-base'
import ButtonBase from '../add/button-base'
import http from '../../../plugins/http'
import apiRoute from '../../../router/api-routes'
export default {
  name: 'add-category',
  components: { ButtonBase, PopupBase },
  props: {
    categoryData: Object
  },
  data () {
    return {
      label: '',
      description: '',
      type: '',
      file: '',
      url: '',
      fileName: '',
      error: false,
      errorAdd: '',
      pictureId: '',
      loader: false
    }
  },
  created () {
    if (this.categoryData) {
      this.label = this.categoryData.label
      this.description = this.categoryData.description
      this.getUserPicture()
      this.pictureId = this.categoryData.icon
    }
  },
  methods: {
    getUserPicture () {
      if (this.categoryData.icon !== '') {
        http.get(apiRoute.baseURL + apiRoute.getFileById + this.categoryData.icon, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.loginToken}`
          }
        })
          .then(response => {
            console.log(response)
            this.fileName = response.file
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    async saveCategory () { // ADD NEW CATEGORY
      if (this.label === '' || this.description === '' || this.fileName === '') {
        this.error = true
        this.errorAdd = this.$t('errorAddAdmin')
      } else {
        this.loader = true
        this.error = false
        if (this.categoryData) {
          await http.patch(apiRoute.baseURL + apiRoute.updateCategory, {
            id: this.categoryData.id,
            label: this.label,
            description: this.description,
            icon: this.pictureId,
            type: 'BLOG'
          }, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.loginToken}`
            }
          }).then(response => {
            console.log(response)
            this.$emit('openSuccessAdd', true)
          }).catch(error => {
            console.log(error)
          })
        } else {
          // await this.createFileId()
          await http.post(apiRoute.baseURL + apiRoute.createCategory, {
            label: this.label,
            description: this.description,
            icon: this.pictureId,
            type: 'BLOG'
          }, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.loginToken}`
            }
          }).then(response => {
            console.log(response)
            this.$emit('openSuccessAdd', true)
          }).catch(error => {
            console.log(error)
          })
        }
      }
    },
    choosePicture () { // CHOOSE CATEGORY PICTURE
      const input = document.createElement('input')
      input.type = 'file'
      input.setAttribute('accept', 'image/*')
      input.onchange = e => {
        this.file = e.target.files[0]
        this.url = URL.createObjectURL(this.file)
        const reader = new FileReader()
        reader.readAsDataURL(this.file)
        reader.onload = a => {
          this.fileName = reader.result
          this.createFileId()
        }
      }
      input.click()
    },
    createFileId () { // CREATE CATEGORY IMAGE ID
      http.post(apiRoute.baseURL + apiRoute.createFile, {
        file: this.fileName
      }, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.loginToken}`
        }
      }).then(response => {
        console.log(response)
        this.pictureId = response.id
      }).catch(error => {
        console.log(error)
      })
    },
    goBack () { // GO BACK TO CATEGORY LIST
      this.$emit('closeAddCategory', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/style/sass/variables";
  .svg-fill {
    fill: transparent;
  }
  .ipt {
    background-color: $light_gray3;
    color: black;
  }
  .login-button::v-deep {
    .button-base {
      .page-button-real {
        width: 100%;
      }
    }
  }
  .close-pic {
    width: fit-content;
    top: -15px;
    right: -10px;
  }
</style>
