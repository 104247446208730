<template>
  <div class="category-list">
    <div class="begin">
      <div class="debt rounded-2xl p-12 mb-8 cursor-pointer">
        <div class="flex items-center justify-end ml-4">
          <div class="update mr-4" @click.stop="$emit('openUpdateCategory', categoriesLists)">
            <svg-icon
              name="ic_update"
              original
              class="w-4 h-4"
            />
          </div>
          <div class="delete" @click.stop="$emit('openConfirmationCategory', categoriesLists.id)">
            <svg-icon
              name="ic_delete-red"
              original
              class="w-4 h-4"
            />
          </div>
        </div>
        <div class="flex items-center justify-between">
          <div class="categ">
            <div class="title text-black font-medium text-lg">{{ categoriesLists.label }}</div>
            <div class="article mt-3 text-sm">
              <span v-if="categoriesLists.totalArticle < 2">{{ categoriesLists.totalArticle }} Article et astuce</span>
              <span v-else>{{ categoriesLists.totalArticle }} Articles et astuces</span>
            </div>
          </div>
          <div class="icon">
            <svg-icon
              name="ic_arrow-right"
              original
              class="w-6 h-6 cursor-pointer"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'category-list',
  props: {
    categoriesLists: {
      type: Object,
      default: function () {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/style/sass/variables";
  .debt {
    background: #F0F0F0;
  }
</style>
